import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
} from "@mui/material";
import "./header.scss";
import Logo from "../assets/home/logo.png";


export default function Header(props) {
  const full_name = "Demo user";
  const screenWidth = window.outerWidth;
  const [scrolled, setScrolled] = useState(false);
  const listenScrollEvent = (e) => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, [listenScrollEvent, screenWidth]);

  return (
    <div>
      {full_name ? (
        <AppBar
          position="fixed"
          className={`root ${scrolled ? "root-scrolled" : ""}`}
        >
          <Toolbar
            variant="dense"
            className="toolbar"
            style={{ height: "68px" }}
          >
            <Box className="navbar-logo">
              <img
                src={Logo}
                alt="logo"
                className={`navbarLogo1 ${
                  screenWidth <= 960 ? "navbarLogo1-small" : ""
                }`}
              />
              <span className="sq_header">
                <h3>
                  <span style={{ color: "#FFFFFF" }}>DEV.TECHYATRA</span>
                </h3>
              </span>
            </Box>
            <Box className="navButton-right">
              <div className="navbarButton">
                <Box display={{ xs: "block", sm: "block", md: "block" }}>
                  <a
                    href="https://wa.me/918793635952"
                    target="_blank"
                    type="button"
                    className="navbarcontactusButton"
                    style={{
                      padding: "0.3rem",
                      fontSize: "0.8rem",
                      color: "white",
                    }}
                  >
                    Contact Us
                  </a>
                </Box>
              </div>
            </Box>
          </Toolbar>
        </AppBar>
      ) : null}
    </div>
  );
}
