import React from "react";
import "./footer.scss";
import { Grid, Box } from "@mui/material";
import "./footer.scss";
import Logo from "../assets/home/logo.png";
const screenWidth = window.outerWidth;

function Footer() {
  return (
    <Grid
      id="footerContainer"
      container
      direction={{ md: "row", xs: "column" }}
      align-items={{ md: "center", xs: "center" }}
      rowSpacing={{ md: 4, xs: 8 }}
      justifyContent={{ md: "space-evenly", xs: "space-evenly" }}
      style={{ background: "#000000", width: "100vw", overflow: "clip" }}
    >
      <Grid
        item
        container
        direction={{ md: "column", xs: "column" }}
        className="firstBox"
        md={6}
        xs={12}
        style={{
          marginBottom: screenWidth <= 960 ? "8rem" : "4rem",
          marginLeft: screenWidth <= 960 && "1rem",
        }}
      >
        <Grid item className="firstBoxItem1" style={{ marginTop: "2rem" }}>
          <Box className="navbar-logo">
            <img
              src={Logo}
              alt="logo"
              className={`navbarLogo1 ${
                screenWidth <= 960 ? "navbarLogo1-small" : ""
              }`}
              style={{marginLeft: "1rem"}}
            />
            <span className="sq_header">
              <h3>
                <span style={{ color: "#FFFFFF", marginLeft: "1rem" }}>DEV.TECHYATRA</span>
              </h3>
            </span>

          </Box>
        </Grid>

        <Grid item className="firstBoxItem3">

        </Grid>
        <Grid item className="firstBoxItem4">
          {/* <Typography className="copyRights" variant="body1" align="left">
            © 2024. All rights reserved | Powered by{" "}
            <a
              href="https://digiigo.com/"
              target="_blank"
              style={{ color: "#FFFFFF" }}
            >
              Digiigo Technologies
            </a>
          </Typography> */}
        </Grid>
      </Grid>

      <Grid md={6} xs={0}></Grid>
    </Grid>
  );
}

export default Footer;
